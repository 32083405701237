import React, { Component } from "react";
// import { connect } from 'react-redux';
import "./PopUp.scss";

class PopUp extends Component {
  openProfilePage = () => {
    window.location.href = process.env.REACT_APP_LEVIS_PROFILE;
  };

  render() {
    if (this.props.statusMessage === "SUCCESS") {
      return (
        <div className="pyro">
          <div className="before"></div>
          <div className="popup-wrapper">
            <div className="popup-background">
              <div className="popup-heading">
                YOU'RE A <br /> WINNER!
              </div>
              <div className="popup-prize">
                <div className="popup-prize-text">
                You've received a Levi's® voucher worth {this.props.item}
                </div>
                <div className="popup-prize-text">
                  Thanks for playing! Head to your profile page to claim your
                  voucher.
                </div>
              </div>
              <div className="after"></div>

              <div className="popup-button">
                <button className="button" onClick={this.openProfilePage}>
                  Go to my profile
                </button>
              </div>
              {this.props.chances > 0 && (
                <div className="popup-button">
                  <button className="button" onClick={this.props.onClick}>
                    Spin again
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="after"></div>
        </div>
      );
    } else if (this.props.statusMessage === "INACTIVE") {
      return (
        <div className="popup-wrapper">
          <div className="popup-background-warning">
            {/* <div className='popup-background-warning-content'> */}
            <div className="popup-heading">Warning !!!</div>
            <div className="popup-prize-warning">
              The campaign is inactive you can not redeem the reward.
            </div>
            <div className="popup-button">
              <button className="button" onClick={this.props.onClick}>
                Close
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="popup-wrapper">
          <div className="popup-background-warning">
            <div className="popup-heading">Warning !!!</div>
            <div className="popup-prize-warning">Error, please try again.</div>
            <div className="popup-button">
              <button className="button" onClick={this.props.onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PopUp;
